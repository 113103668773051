/** Embed map needs space for the top header */
.wrapper.embed_wrapper.regular {
  height: calc(100vh - 6.7rem);
}

.wrapper.embed_wrapper.compact {
  height: calc(100vh - 3.35rem);
}

.header_embed_regular {
  height: 6.7rem;
  padding: 0 20px 10px 20px;
  border-bottom: 1px black solid;
}

.header_embed_compact {
  height: 3.35rem;
  padding: 0 20px 0 20px;
  border-bottom: 1px black solid;
}

.route_sidebar {
  position: fixed;
  z-index: 99999;
}

.route_manipulation_tools .bg-header {
  margin-left: 0 !important;
  /*padding: 0 0.5rem 0 0 !important;*/
}
.route_manipulation_tools .draw_icon {
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.route_manipulation_tools .draw_element {
  text-align: center;
  margin-top: 8px;
}
.route_manipulation_tools .draw_element .bootstrap-select {
  height: 100%;
}
.route_manipulation_tools .draw_icon.leaflet-disabled .col,
.route_manipulation_tools .draw_icon.leaflet-disabled .col:hover {
  background: gray;
  color: inherit;
  text-decoration: unset;
  cursor: default;
}

@media (min-width: 1200px) {
  .route_manipulation_tools.top {
    padding-top: 1rem !important;
  }
  .route_manipulation_tools:not(.top) {
    top: unset;
    align-items: center !important;
  }
  .route_manipulation_tools.left .bg-header {
    margin-left: 0.5rem !important;
  }
  .route_manipulation_tools.right .bg-header {
    margin-right: 0.5rem !important;
  }
}
.route_manipulation_tools:not(.embed, .presenter) {
  top: 0;
}

.route_sidebar.right:not(.embed) {
  top: 86px;
}

.route_manipulation_tools.mobile:not(.embed, .presenter) {
  top: 70px;
}

.route_sidebar.right.mobile:not(.embed) {
  top: 70px;
}

.route_manipulation_tools.right {
  right: 0;
}